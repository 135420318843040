import { DetailsListLayoutMode, IColumn, Selection, Link, IDragDropContext, IDragDropEvents, IListProps, SelectionMode, Text, CommandBar, ICommandBarItemProps, IButtonProps, IPersonaSharedProps, Persona, PersonaSize, getTheme, SearchBox, Stack, Callout, DirectionalHint, IconButton, TagPicker, IBasePickerSuggestionsProps, IInputProps, ITag, TagItemSuggestion, FontIcon, TagItem, ISuggestionsProps, ISuggestionItemProps, ISuggestionModel, TextField, ITextFieldProps, Label, Overlay, ComboBox, Dropdown, PrimaryButton, DefaultButton, SpinButton, IContextualMenuProps, ShimmeredDetailsList, FocusZone, FocusZoneTabbableElements, FontWeights, Spinner, SpinnerSize, memoizeFunction, CommandBarButton, IDropdownOption, IStackComponent, ISearchBoxProps, DialogFooter, CheckboxVisibility, TooltipHost, Icon } from "@fluentui/react"
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../../app/Hooks"
import { getPage } from "../../../../../app/Pages"
import { setPage } from "../../../../layouts/Master/MasterLayoutSlice"
import { setTitle } from "../../../../common/NavHeader/NavHeaderSlice"
import { useSetInterval, useConst } from '@fluentui/react-hooks';
import { Table } from "../../../../common/Table/Table"
import { DeleteCustomerRequest, GetUsersRequest, GetUsersResponse, ResetUserPasswordRequest, UpdateProtectionRequest, UpdateUserActivityRequest, UpdateUserConfirmationStateRequest } from "../../../../../repository/UserManagement/Clients/client_pb"
import { dismissMessage, getCustomerUsers, reset, resetCustomers, resetRoles, resetUserTypes, setActivityFilterOption, setConfirmationFilterOption, setNumberOfResults, setSort, setSearchText, setSelectedCustomer, setSelectedRole, setSelectedUserType, setTwoFactorFilterOption, updateStatus, setIsFilteredCustomersSet, setIsFilteredRolesSet, setIsFilteredUserTypesSet, updateUser, addUser, resetNewPassword, updateUsername, setProtectionFilterOption, updateProtection, deleteUser } from "./UsersPageSlice"
import { ApiMessage, getHeaders } from "../../../../../app/Api"
import { useId, useBoolean } from '@fluentui/react-hooks';

import * as google_protobuf_wrappers_pb from 'google-protobuf/google/protobuf/wrappers_pb'
import { BoolValue, Int32Value, StringValue } from "google-protobuf/google/protobuf/wrappers_pb"
import { getMainTheme } from "../../../../../app/Themes"
import { SearchField } from "../../../../common/SearchField/SearchField"
import { formatDate, printComponent, normalizeKey } from "../../../../../app/Helpers"
import { TableState } from "../../../../common/Table/TableSate"
import { Message } from "../../../../common/Message/Message"
import { ShareBox } from "../../../../common/ShareBox/ShareBox"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { SessionExpiredDialog } from "../../../../common/SessionExpiredDialog/SessionExpiredDialog"
import React from "react"
import { bool, string } from "yup"
import { Popup } from "../../../../common/Popup/Popup"
import { UsersForm } from "../../../../forms/Customers/Users/UsersForm"
import { FormType } from "../../../../forms/FormProps"
import { useReactToPrint } from "react-to-print"
import { PageProps } from "../../../PageProps"
import { List } from "../../../../common/List/List"
import { UserTypesPage } from "../../UserTypes/UserTypesPage"
import { RolesPage } from "../../Roles/RolesPage"

const shimmeredDetailsListProps: IListProps = {
    renderedWindowsAhead: 0,
    renderedWindowsBehind: 0,
};


let req: GetUsersRequest;


let org: number = -1;

let getCustomersPromise: any;
let getRolesPromise: any;
let getUserTypesPromise: any;
let actionPromise: any;


const key = "clients/users"
export const UsersPage: React.FunctionComponent<PageProps> = (props) => {


    const dispatch = useAppDispatch()
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const state: {
        isChangeStateLoading: boolean,
        customers: TableState, message: ApiMessage | undefined, roles: TableState,
        appTheme: string, isDarkModeEnabled: boolean, userTypes: TableState, searchText: string | undefined,
        activityFilterOption: { key: number, text: string },
        confirmationFilterOption: { key: number, text: string },
        protectionFilterOption: { key: number, text: string },
        twoFactorFilterOption: { key: number, text: string },
        filters: any[],
        newPassword: string | undefined
    } = useAppSelector((state) => {
        return {
            isChangeStateLoading: state.clientsPage.isChangeStateLoading,
            customers: state.clientsPage.customers, message: state.clientsPage.message, roles: state.clientsPage.roles,
            appTheme: state.settings.appTheme, isDarkModeEnabled: state.settings.isDarkModeEnabled, userTypes: state.clientsPage.userTypes,
            searchText: state.clientsPage.searchText, activityFilterOption: state.clientsPage.activityFilterOption,
            confirmationFilterOption: state.clientsPage.confirmationFilterOption, twoFactorFilterOption: state.clientsPage.twoFactorFilterOption,
            protectionFilterOption: state.clientsPage.protectionFilterOption,
            filters: state.clientsPage.filters,
            newPassword: state.clientsPage.newPassword
        }
    })
    const calloutTargetId = useId('callout-target');
    const labelId = useId('callout-label');
    const statusActionConfirmationcalloutTargetId = useId('status-action-callout-target');
    const confirmActionConfirmationcalloutTargetId = useId('confirm-action-callout-target');
    const twoFactorActionConfirmationcalloutTargetId = useId('twof-action-callout-target');
    const resetPasswordActionConfirmationcalloutTargetId = useId('reset-pass-action-callout-target');
    const protectionActionConfirmationcalloutTargetId = useId('protection-action-callout-target');
    const deleteActionConfirmationcalloutTargetId = useId('delete-action-callout-target');

    const ActionConfirmationLabelId = useId('action-callout-label');
    const shareCalloutLabelId = useId('share-callout-label');
    const shareTargetId = useId('share-callout-target');

    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isActionConfirmationCalloutVisible, { toggle: toggleisActionConfirmationCalloutVisible }] = useBoolean(false);
    const [isShareCalloutVisible, { toggle: toggleisShareCalloutVisible }] = useBoolean(false);

    const [currentAction, setCurrenctAction] = useState(0)
    const ref = useRef<HTMLDivElement>(null);
        const reactToPrintContent = () => {
        return printComponent(ref.current?.cloneNode(true), true);
    };

    const handlePrint = useReactToPrint({});

    const getActionId = (action: number): string => {
        if (action == 1)
            return statusActionConfirmationcalloutTargetId;
        else if (action == 2) {
            return confirmActionConfirmationcalloutTargetId;
        } else if (action == 3) {
            return twoFactorActionConfirmationcalloutTargetId;
        } else if (action == 4) {
            return resetPasswordActionConfirmationcalloutTargetId;
        } else if (action == 5) {
            return protectionActionConfirmationcalloutTargetId;
        } else if (action == 6) {
            return deleteActionConfirmationcalloutTargetId;
        } else {
            return ""
        }
    }

    const _selection = useConst(() => new Selection({
        onSelectionChanged: () => {
            if (_selection.count > 0) {
                dispatch(setSelectedCustomer(_selection.getSelection()));
            } else {
                dispatch(setSelectedCustomer([]));
            }
        },

    }));

    const clearFilter = () => {
        dispatch(setSelectedRole([]))
        req.setRole(undefined)
        dispatch(setSelectedUserType([]))
        req.setUsertype(undefined)
        dispatch(setActivityFilterOption({ key: 0, text: "" }))
        req.setIsactive(undefined)
        dispatch(setConfirmationFilterOption({ key: 0, text: "" }))
        req.setIsconfirmed(undefined)

    }

    const search = (searchText: string | undefined) => {
        let isFiltered: boolean = false;
        if (searchText) {
            const wrapper = new StringValue();
            wrapper.setValue(searchText.trim());
            req.setSearch(wrapper)
            isFiltered = true;
        } else {
            req.setSearch(undefined)
        }
        if (state.roles.selected.length > 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(Number(state.roles.selected.at(0)?.id));
            req.setRole(wrapper)
            isFiltered = true;

        } else {
            req.setRole(undefined)
        }

        if (state.userTypes.selected.length > 0) {
            const wrapper = new Int32Value();
            wrapper.setValue(Number(state.userTypes.selected.at(0)?.id));
            req.setUsertype(wrapper)
            isFiltered = true;


        } else {
            req.setUsertype(undefined)
        }

        if (state.activityFilterOption.key != 0) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.activityFilterOption.key == 1);
            req.setIsactive(wrapper)
            isFiltered = true;

        } else {
            req.setIsactive(undefined)
        }

        if (state.confirmationFilterOption.key) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.confirmationFilterOption.key == 1);
            req.setIsconfirmed(wrapper)
            isFiltered = true;

        } else {
            req.setIsconfirmed(undefined)
        }
        if (state.protectionFilterOption.key) {
            const wrapper = new BoolValue();
            wrapper.setValue(state.protectionFilterOption.key == 1);
            req.setIsprotected(wrapper)
            isFiltered = true;

        } else {
            req.setIsprotected(undefined)
        }
        req.setNextto(undefined)
        dispatch(resetCustomers())
        dispatch(setIsFilteredCustomersSet(isFiltered))
        getCustomersPromise?.abort()
        getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))
    }


    useEffect(() => {
        req = new GetUsersRequest();


        var l = searchParams.get('org');
        if (l) {
            org = Number(l);
            if (Number.isNaN(org)) {
                org = -1;
            } else {
                const wrapper = new Int32Value();
                wrapper.setValue(org);
                req.setOrganization(wrapper)

            }
        }
        if (!props.isSelection) {
            const page = getPage(key)
            dispatch(setTitle(t(normalizeKey(page?.title))));
            dispatch(setPage(page));
        }
        req.setNextto(undefined)
        req.setNumofresults(state.customers.numberOfResults)
        req.setOrder(state.customers.isDescending)


        getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))
        return () => { //clean up
            getCustomersPromise?.abort();
            getRolesPromise?.abort();
            getUserTypesPromise?.abort();
            dispatch(reset());
        }
    }, [])


    const c: IColumn[] = [

        {
            key: 'name',
            name: t("client"),
            minWidth: 20,
            maxWidth: 250,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                if (item?.isProtected) {
                    return <div>
                        <Link onClick={(e) => {
                            if (e) {
                                e.preventDefault();
                                navigate("/clients/users/" + item?.internalId + ((org == -1) ? "" : "?org=" + org));
                            }
                        }
                        } href={"/clients/users/" + item?.internalId + ((org == -1) ? "" : "?org=" + org)} >  <Stack horizontal> <Persona
                            text={item?.name == "" ? item?.internalId : item?.name}
                            secondaryText={item?.username}
                            size={PersonaSize.size40}

                        />   <TooltipHost content={t("protectedAccountDesc")}>
                                    <Icon iconName="LockSolid" aria-label={t("protectedAccountDesc")} />
                                </TooltipHost>       </Stack>
                        </Link>
                    </div>;
                } else {
                    return <Link onClick={(e) => {
                        if (e) {
                            e.preventDefault();
                            navigate("/clients/users/" + item?.internalId + ((org == -1) ? "" : "?org=" + org));
                        }
                    }
                    } href={"/clients/users/" + item?.internalId + ((org == -1) ? "" : "?org=" + org)} ><Persona
                            text={item?.name == "" ? item?.internalId : item?.name}
                            secondaryText={item?.username}
                            size={PersonaSize.size40}

                        /></Link>;
                }



            },
        },


        {
            key: 'usertype',
            name: t("clientType"),
            minWidth: 20,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/customers/user-types/" + item?.usertypeId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/user-types/" + item?.usertypeId + ((org == -1) ? "" : "?org=" + org)} > {item?.usertype == "" ? item?.usertypeId : item?.usertype}</Link >
            },
        },

        {
            key: 'role',
            name: t("subscription"),
            minWidth: 20,
            maxWidth: 50,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Link onClick={(e) => {
                    if (e) {
                        e.preventDefault();
                        navigate("/customers/roles/" + item?.roleId + ((org == -1) ? "" : "?org=" + org));
                    }
                }
                } href={"/customers/roles/" + item?.roleId + ((org == -1) ? "" : "?org=" + org)} > {item?.role == "" ? item?.roleId : item?.role}</Link >
            },
        },
        {
            key: 'isActive',
            name: t("accountStatus"),
            minWidth: 50,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {
                return <Text>{item?.isActive ? t("active") : t("inactive")}</Text>;
            },
        },
        {
            key: 'isConfirmed',
            name: t("identity"),
            minWidth: 20,
            maxWidth: 80,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {

                return <Text>{item?.isConfirmed ? t("confirmed") : t("unconfirmed")}</Text>;
            },
        },


        {
            key: 'createdDate',
            name: t("createdDate"),
            minWidth: 20,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item) => {


                return <Text>{(item?.createdDate)}</Text>; //{item.createdDate}
            },
        },




    ];

    const _selectionItems: ICommandBarItemProps[] = [

        {
            key: 'status',
            text: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isActive) ? t("deactivateAccount") : t("activateAccount"),
            title: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isActive) ? t("deactivateAccount") : t("activateAccount"),
            iconProps: { id: statusActionConfirmationcalloutTargetId, iconName: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isActive) ? "UserRemove" : "UserFollowed", styles: { root: { fontWeight: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isActive) ? undefined : "bold" } } },
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 1 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 1 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 1 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(1)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },

        {
            key: 'protection',
            text: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isProtected) ? t("disableProtection") : t("enableProtection"),
            title: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isProtected) ? t("disableProtection") : t("enableProtection"),
            iconProps: { id: protectionActionConfirmationcalloutTargetId, iconName: (state.customers.selected.length > 0 && state.customers.selected.at(0)?.isProtected) ? "UnLock" : "LockSolid" },
            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 5 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 5 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 5 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(5)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'delete',
            text: t("delete"),
            title: t("delete"),
            iconProps: { id: deleteActionConfirmationcalloutTargetId, iconName: "Delete", styles: { root: { fontWeight: "bold" } } },

            commandBarButtonAs: (e) => {
                return <CommandBarButton {...(e as IButtonProps)} iconProps={state.isChangeStateLoading && currentAction == 6 ? undefined : e.iconProps} text={state.isChangeStateLoading && currentAction == 6 ? undefined : e.text}  >
                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.isChangeStateLoading && currentAction == 6 ? "block" : "none") } }} />
                </CommandBarButton>;
            },
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    setCurrenctAction(6)
                    toggleisActionConfirmationCalloutVisible()
                }
            }
        },
        {
            key: 'logs',
            text: t("logs"),
            iconProps: { iconName: "PageList", styles: { root: { fontWeight: "bold" } } },
            title: t("logs"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    navigate("/clients/logs?resourceId=" + state.customers.selected.at(0)?.internalId + ((org == -1) ? "" : "&org=" + org));
                }
            }
        },
        {
            key: 'share',
            text: t("share"),
            title: t("share"),
            iconProps: { id: shareTargetId, iconName: 'Share', styles: { root: { fontWeight: "bold" } } },
            onClick: () => {
                toggleisShareCalloutVisible()
            },

        },

    ];

    const mainOptions: ICommandBarItemProps[] = [

        {
            key: 'refresh',
            text: t('refresh'),
            title: t("refresh"),
            iconProps: { iconName: 'refresh' },
            disabled: state.customers.isFetching,
            onClick: () => {
                dispatch(resetCustomers())
                req.setNextto(undefined)
                //   if (!state.searchText || state.searchText?.trim().length == 0) {
                //     req.setSearch(undefined)
                //}
                getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))
            }
        },
        {
            key: 'print',
            text: t("print"),
            iconProps: { iconName: "Print", styles: { root: { fontWeight: "bold" } } },
            title: t("print"),
            onClick: () => {
                if (!state.isChangeStateLoading) {
                    handlePrint(reactToPrintContent);
                }
            }
        },
    ];
    const _farOptions: ICommandBarItemProps[] = [
        {
            key: 'sort',
            text: t('sort'),
            title: t("sort"),
            iconProps: { iconName: 'sort' },
            disabled: state.customers.isFetching,
            subMenuProps: {
                items: [{
                    key: 'ascending',
                    text: t('ascendingDate'),
                    title: t("ascendingDate"),
                    canCheck: true,
                    checked: !state.customers.isDescending,
                    onClick: () => {
                        dispatch(setSort(false))
                        dispatch(resetCustomers())
                        req.setOrder(false)
                        req.setNextto(undefined)
                        getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))

                    }


                }, {
                    key: 'descending',
                    text: t('descendingDate'),
                    title: t("descendingDate"),
                    canCheck: true,
                    checked: state.customers.isDescending,
                    onClick: () => {
                        dispatch(setSort(true))
                        dispatch(resetCustomers())
                        req.setOrder(true)
                        req.setNextto(undefined)
                        getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))

                    }

                }]
            }
        }


    ];
    const _farBottomOptions: ICommandBarItemProps[] = [
        {
            key: 'results',
            text: t('results') + ': ' + state.customers.numberOfResults,
            title: t('results') + ': ' + state.customers.numberOfResults,
            iconProps: { iconName: 'ShowResults' },
            subMenuProps: {
                items: [{
                    key: '10',
                    text: '10',
                    title: '10',
                    canCheck: true,
                    checked: (state.customers.numberOfResults == 10 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(10))
                        req.setNumofresults(10)
                    }


                }, {
                    key: '50',
                    text: '50',
                    title: '50',
                    canCheck: true,
                    checked: (state.customers.numberOfResults == 50 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(50))
                        req.setNumofresults(50)
                    }

                },
                {
                    key: '100',
                    text: '100',
                    title: '100',
                    canCheck: true,
                    checked: (state.customers.numberOfResults == 100 ? true : false),
                    onClick: () => {
                        dispatch(setNumberOfResults(100))
                        req.setNumofresults(100)
                    }

                }]
            }
        }
    ];





    return (
        <Stack >




            {state.message != undefined ? (state.message.data != 401) ? <Message
                body={state.message.body}
                title={state.message.title}
                data={state.message.data}
                onDismiss={() => { dispatch(dismissMessage()) }}
                type={state.message.type}
            /> :
                <SessionExpiredDialog />
                : null
            }
            <CommandBar
                items={state.customers.selected.length == 0 ? mainOptions : _selectionItems}
                farItems={_farOptions}

            //id={actionConfirmationcalloutTargetId}


            />
            {isShareCalloutVisible ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${shareTargetId}`}
                    onDismiss={toggleisShareCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={toggleisShareCalloutVisible} title={t("shareProfile")} value={`https://${window.location.host}/clients/users/${state.customers.selected.at(0).internalId}` + ((org == -1) ? "" : "?org=" + org)}></ShareBox>
                </Callout>
            ) : null}

            {state.newPassword ? (
                <Callout
                    ariaLabelledBy={shareCalloutLabelId}
                    target={`#${resetPasswordActionConfirmationcalloutTargetId}`}
                    onDismiss={() => { dispatch(resetNewPassword()) }}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <ShareBox onDismiss={() => { dispatch(resetNewPassword()) }} isSecret={true} title={t("newPasswordTitle")} value={state.newPassword}></ShareBox>
                </Callout>
            ) : null}
            {isActionConfirmationCalloutVisible ? (
                <Callout
                    ariaLabelledBy={ActionConfirmationLabelId}
                    target={`#${getActionId(currentAction)}`}
                    onDismiss={toggleisActionConfirmationCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    beakWidth={20}
                    styles={{
                        root: {
                            width: 370,
                        }
                    }}
                >
                    <Stack tokens={{ padding: '20px 24px ' }}>
                        <Text block variant="xLarge" styles={{ root: { marginBottom: 12, fontWeight: FontWeights.semilight } }}>
                            {(currentAction == 1 ? (state.customers.selected.at(0)?.isActive) ? t("deactivateAccount") : t("activateAccount") : currentAction == 2 ? state.customers.selected.at(0)?.isConfirmed ? t("identityUnconfirm") : t("identityConfirm") : currentAction == 4 ? t("resetPassword") : currentAction == 5 ? (state.customers.selected.at(0)?.isProtected) ? t("disableProtection") : t("enableProtection") :  currentAction == 6 ? t("delete") : "")}
                        </Text>
                        <Text block variant="small">
                            {(currentAction == 1 ? (state.customers.selected.at(0)?.isActive) ? t("deactivateAccountDesc") : t("activateAccountDesc") : currentAction == 2 ? state.customers.selected.at(0)?.isConfirmed ? t("identityUnconfirmDesc") : t("identityConfirmDesc") : currentAction == 4 ? t("resetPasswordDesc") : currentAction == 5 ? (state.customers.selected.at(0)?.isProtected) ? t("disableProtectionDesc") : t("enableProtectionDesc") : currentAction == 6 ? t("deleteUserDesc") : "")}

                        </Text>
                        {/* This FocusZone allows the user to go between buttons with the arrow keys.
              It's not related to or required for FocusTrapCallout's built-in focus trapping. */}
                        <FocusZone handleTabKey={FocusZoneTabbableElements.all} isCircularNavigation>
                            <Stack reversed tokens={{ childrenGap: 8, padding: "20px 0px 0px 0px" }} horizontal>
                                <DefaultButton autoFocus onClick={toggleisActionConfirmationCalloutVisible}>{t("cancel")}</DefaultButton>

                                <PrimaryButton onClick={() => {
                                    toggleisActionConfirmationCalloutVisible()
                                    if (state.customers.selected.length > 0) {
                                        if (currentAction == 1) {
                                            let req = new UpdateUserActivityRequest();
                                            req.setProfileid(state.customers.selected.at(0)?.internalId)
                                            req.setIsaccountactive(!state.customers.selected.at(0)?.isActive)

                                            dispatch(updateStatus({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 2) {
                                            let req = new UpdateUserConfirmationStateRequest();
                                            req.setProfileid(state.customers.selected.at(0)?.internalId)
                                            req.setIsaccountconfirmed(!state.customers.selected.at(0)?.isConfirmed)
                                            //  dispatch(updateConfirmationStatus({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 4) {
                                            let req = new ResetUserPasswordRequest();
                                            req.setProfileid(state.customers.selected.at(0)?.internalId)
                                            //  dispatch(resetPassword({ body: req, headers: getHeaders() }))
                                        } else if (currentAction == 5) {
                                            let req = new UpdateProtectionRequest();
                                            req.setProfileid(state.customers.selected.at(0)?.internalId)
                                            req.setIsprotected(!state.customers.selected.at(0)?.isProtected)

                                            dispatch(updateProtection({ body: req, headers: getHeaders() }))

                                        } else if (currentAction == 6) {
                                            let req = new DeleteCustomerRequest();
                                            req.setProfileid(state.customers.selected.at(0)?.internalId)
                                            actionPromise = dispatch(deleteUser({ body: req, headers: getHeaders() }))
                                            actionPromise.unwrap().then((e: any) => {
                                                _selection.setAllSelected(false)
                                            })
                                        }
                                    }
                                }} text={(currentAction == 1 ? (state.customers.selected.at(0)?.isActive) ? t("deactivateAccount") : t("activateAccount") : currentAction == 2 ? state.customers.selected.at(0)?.isConfirmed ? t("identityUnconfirm") : t("identityConfirm") : currentAction == 4 ? t("reset") : currentAction == 5 ? (state.customers.selected.at(0)?.isProtected) ? t("disable") : t("enable") : currentAction == 6 ? t("delete") : "")}  >

                                </PrimaryButton>
                            </Stack>
                        </FocusZone>
                    </Stack>
                </Callout>
            ) : null}

            {isCalloutVisible ? (
                <Callout
                    ariaLabelledBy={labelId}

                    target={`#${calloutTargetId}`}
                    onDismiss={toggleIsCalloutVisible}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                    isBeakVisible={true}
                >
                    <Stack tokens={{ padding: 28, childrenGap: 30 }}  >
                        <Text block variant="mediumPlus" >
                            {t("filterDesc")}
                        </Text>



                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("accountStatus")}</Label>
                            <Dropdown
                                selectedKey={state.activityFilterOption.key}
                                options={[{ key: 0, text: t("activeAndInactive") }, { key: 1, text: t("active") }, { key: 2, text: t("inactive") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {

                                    dispatch(setActivityFilterOption({ key: Number(item?.key), text: t("accountStatus") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("identity")}</Label>
                            <Dropdown
                                selectedKey={state.confirmationFilterOption.key}
                                options={[{ key: 0, text: t("confirmedAndUnconfirmed") }, { key: 1, text: t("confirmed") }, { key: 2, text: t("unconfirmed") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setConfirmationFilterOption({ key: Number(item?.key), text: t("identity") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <Stack horizontalAlign="space-between" horizontal tokens={{ childrenGap: 20 }} >
                            <Label>{t("protection")}</Label>
                            <Dropdown
                                selectedKey={state.protectionFilterOption.key}
                                options={[{ key: 0, text: t("enabledAndDisabled") }, { key: 1, text: t("enabled") }, { key: 2, text: t("disabled") }]}
                                styles={{ root: { width: 300 } }}
                                onChange={((e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
                                    dispatch(setProtectionFilterOption({ key: Number(item?.key), text: t("protection") + ":" + item?.text }))

                                })}

                            />
                        </Stack>

                        <DialogFooter>
                            <PrimaryButton text={t("search")} onClick={(e => {
                                search(state.searchText);
                                toggleIsCalloutVisible();
                            })} />
                            <DefaultButton text={t("clearFilter")} onClick={() => {
                                clearFilter();
                            }} />

                        </DialogFooter>
                    </Stack>
                </Callout>
            ) : null}

            <Stack.Item align="center"  >
                <Stack horizontal  >
                    <SearchField
                        inputProps={{
                            maxLength: 250,
                            placeholder: t("search"),
                        }}

                        onInputChange={(e) => {
                            dispatch(setSearchText(e));
                            return e;
                        }}
                        endButtons={[
                            { id: calloutTargetId, title: t("filter"), iconProps: { iconName: "filter" }, onClick: toggleIsCalloutVisible },
                            {
                                title: t("search"), checked: true, iconProps: { iconName: "search" }, onClick: () => {
                                    search(state.searchText);
                                    if (isCalloutVisible)
                                        toggleIsCalloutVisible();

                                }
                            }]}
                        onChange={(e) => {
                            const res = state.filters.filter(({ key: key1 }) => !e?.some(({ key: key2 }) => key1 === key2));
                            res?.forEach(e => {
                                if (e.key == 1) {
                                    dispatch(setSelectedRole([]))
                                    req.setRole(undefined)
                                } else if (e.key == 2) {
                                    dispatch(setSelectedUserType([]))
                                    req.setUsertype(undefined)
                                } else if (e.key == 3) {
                                    dispatch(setActivityFilterOption({ key: 0, text: "" }))
                                    req.setIsactive(undefined)
                                } else if (e.key == 4) {
                                    dispatch(setConfirmationFilterOption({ key: 0, text: "" }))
                                    req.setIsconfirmed(undefined)
                                } else if (e.key == 5) {

                                } else if (e.key == 6) {
                                    dispatch(setProtectionFilterOption({ key: 0, text: "" }))
                                    req.setIsprotected(undefined)

                                }
                            });

                        }}

                        onResolveSuggestions={() => { return [] }}
                        selectedItems={state.filters.map(e => { return { name: e.name, key: e.key } as ITag }) || []}
                        onSearch={(e) => {
                            search(state.searchText);
                            if (isCalloutVisible)
                                toggleIsCalloutVisible();
                        }}
                        onClear={(e) => {
                            dispatch(setSearchText(undefined))
                            if (state.customers.isFilteredSet) {
                                search(undefined);
                            }
                            /*if (req.getSearch() && !state.managers.isFilteredSet) {
                                dispatch(resetRoles())
                                req.setNextto(undefined)
     
                                req.setSearch(undefined)
     
                                getManagersPromise = dispatch(getManagerUsers({ body: req, headers: getHeaders() }))
                            }*/
                        }}
                        showIcon
                    />
                </Stack>
            </Stack.Item>



            <Table
                setKey="clients"
                items={state.customers.items}
                columns={c}
                width={props.isSelection ? '80vw' : undefined}
                height={props.isSelection ? "60vh" : state.message == undefined ? "calc(100vh - 176px)" : "calc(100vh - 224px)"}
                selection={_selection}
                checkboxVisibility={props.isSelection ? CheckboxVisibility.always : CheckboxVisibility.onHover}
                selectionMode={SelectionMode.single}
                disableSelectionZone={state.isChangeStateLoading}
                enableShimmer={state.customers.items.length == 0 && state.customers.isFetching}
                isColumnSortingEnabled={state.customers.selected.length == 0}
                layoutMode={props.isSelection ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justified}
                compRef={ref}
                onScroll={(e) => {
                    const bottom = e?.currentTarget?.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
                    if (bottom && !state.customers.isFetching && state.customers.hasMore) {
                        const wrapper = new StringValue();
                        wrapper.setValue(state.customers.items.at(state.customers.items.length - 11)?.internalId);
                        req.setNextto(wrapper)
                        getCustomersPromise?.abort();
                        getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))

                    }
                }}
                numberOfResultsOptions={[10, 50, 100]}
                selectedNumberOfResults={state.customers.numberOfResults}
                onNumberOfResultsChange={(e) => {
                    dispatch(setNumberOfResults(e))
                    req.setNumofresults(e)
                }}
                onRowDidMount={(item?: any, index?: number) => {
                    //alert(index)
                    if (index == state.customers.items.length - 11) { // last element renderd
                        const { current } = ref;
                        if (current) {
                            if (state.customers.items.length > 0 && !state.customers.isFetching && state.customers.hasMore) {
                                if (current.scrollHeight == current.clientHeight) { // scroll not shown? get more data 
                                    const wrapper = new StringValue();
                                    wrapper.setValue(state.customers.items.at(state.customers.items.length - 11)?.internalId);
                                    req.setNextto(wrapper)
                                    getCustomersPromise?.abort();
                                    getCustomersPromise = dispatch(getCustomerUsers({ body: req, headers: getHeaders() }))
                                }


                            }
                        }
                    }
                }}
            />

            {props.isSelection ? <DialogFooter>
                <PrimaryButton disabled={state.customers.isFetching || state.customers.selected.length == 0} text={state.customers.isFetching ? undefined : t("select")} onClick={() => {
                    if (props.onItemSelected && state.customers.selected.length > 0) {
                        props.onItemSelected(state.customers.selected.at(0))
                    }
                }} >

                    <Spinner size={SpinnerSize.medium} styles={{ root: { display: (state.customers.isFetching ? "block" : "none") } }} />

                </PrimaryButton>
                <DefaultButton disabled={state.customers.isFetching} text={t("discard")} onClick={() => {
                    if (props.onCancel) {
                        props.onCancel()
                    }
                }} />
            </DialogFooter> : undefined}
        </Stack>



    );
}




